import React from 'react'
import { View, Text, StyleSheet, Pressable, Dimensions, Image } from 'react-native'
import { Formulaire } from './Formulaire';

export class Resultat extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      selected: 0,
      erreur: false,
      descriptionLongue: false,
      montrerConsigne: false
    }
    this.reponses = []
  }

  render() {
      return (
        <View>
          <View style={[styles.container, {marginTop: Dimensions.get('screen').height > 700 ? -40 : -80}]}>
            <Text style={[styles.titre, {height: Dimensions.get('screen').height > 700 ? 80 : 60}]}>RÉSULTATS</Text>
            <View style={[styles.descriptionContainer, { borderColor: this.props.tribu.couleur, 
            height: Dimensions.get('window').height > 700 ? 460 : 420}]}>
              {!this.state.montrerConsigne && <Text style={[styles.role, {color: this.props.tribu.couleur}]}>
                Votre animal totem
                </Text>
              }
              {this.state.montrerConsigne ? (
                <Text style={styles.tribu}>Vous souhaitez nous rencontrer pour discuter agilité ensemble ?</Text>
              ) : (
                <Text style={[styles.tribu, {color: this.props.tribu.couleur}]}>
                  {this.props.tribu.tribu} ({this.props.tribu.role})
                </Text>
              )}
              <Text 
                style={[styles.description, {color: this.props.tribu.couleur, marginTop: this.state.montrerConsigne ? 50 : 15,
                fontSize: (Dimensions.get('window').height > 700 || this.state.montrerConsigne) ? 15 : 13, }]}
              >
                {this.state.montrerConsigne ? '' : this.props.tribu.texte_court}
              </Text>
              {this.state.montrerConsigne && <Formulaire/>}
            </View>
            <View style={styles.boutons}>
              <Pressable 
                style={styles.bouton} 
                onPress={() => this.props.descriptionLongue()}
              >
                <Text style={styles.texteBouton}>En savoir plus</Text>
              </Pressable>
              <Pressable 
                style={styles.bouton} 
                onPress={() => this.setState({montrerConsigne: !this.state.montrerConsigne})}
              >
                <Text style={styles.texteBouton}>{this.state.montrerConsigne ? 'Retour' : 'Suivant'}</Text>
              </Pressable>
            </View>
          </View>
          {!this.state.montrerConsigne && 
            <Image 
              source={{uri: this.props.tribu.image}} 
              style={{position: 'absolute', alignSelf: 'flex-end', right: 1, height: 86, width: 86}}
            />
          }
        </View>
      )
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    width: 370,
    minHeight: 500,
    borderRadius: 10,
    overflow: 'hidden'
  },
  descriptionContainer: {
    backgroundColor: 'rgba(255,255,255,0.9)', 
    borderRadius: 10, 
    marginHorizontal: 10, 
    paddingHorizontal: 18, 
    paddingBottom: 10, 
    paddingTop: 8,
    borderWidth: 1,
    overflow: 'hidden'
  },
  titre: {
    alignSelf: 'center',
    fontWeight: 'bold',
    fontSize: 24,
    textAlign: 'center',
    color: '#DA5D00',
    paddingHorizontal: 15,
  },
  role : {
    textAlign: 'center',
    fontSize: 14,
    fontWeight: 'bold',
    marginBottom: 5
  },
  tribu : {
    fontSize: 16,
    fontWeight: 'bold',
    marginTop: 10,
    textAlign: 'center'
  },
  description : {
    fontSize: 13,
    textAlign: 'justify',
    color: '#b04b00',
  },
  bouton: {
    backgroundColor: '#DA5D00',
    alignSelf: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 30,
    height: 35,
    width: 150,
    borderRadius: 10,
  },
  texteBouton : {
    color: 'white',
    fontWeight: 'bold'
  },
  erreur: {
    marginTop: 20,
    color: 'white'
  },
  boutons: {
    flexDirection: 'row',
    justifyContent: 'space-around'
  }
})