import React, { Fragment } from 'react'
import { View, Text, StyleSheet, Image, Dimensions, Pressable } from 'react-native'
import { ImageBackground } from 'react-native-web';
import * as Device from 'expo-device';
import * as Network from 'expo-network';
import { isMobile } from 'react-device-detect';
import { Question } from './Question';
import { Resultat } from './Resultat';

export class Questionnaire extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      questionIndex: 0,
      questions: [],
      done: false,
      tribus: [],
      resultat: {},
      descriptionLongue: false
    }
  }

  componentDidMount() {
    this.envoyerStats();
    const questions = require('../data/questions.json');
    const tribus = require('../data/tribus.json');
    this.setState({ tribus, questions:  questions.sort(() => Math.random() - 0.5).map(question => {
      return {...question, reponses: question.reponses.sort(() => Math.random() - 0.5)}
    })});
  }

  envoyerStats = async () => {
    try {
      let ip = 'protected';
      try {
        ip = await Network.getIpAddressAsync() || 'protected';
      } catch {}
      const connectionType = await Device.getDeviceTypeAsync();
  
      fetch('https://www.znoue.org/api/connection/', {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ip: ip, type: Device.DeviceType[connectionType]})
      });
    } catch {}
  }

  questionSuivante = (reponses) => {
    if (this.state.questionIndex < this.state.questions.length - 1) {
      this.setState({questionIndex: this.state.questionIndex + 1})
    } else {
      this.setState({done: true}, () => {
        let a = 0;
        let b = 0;
        let c = 0;
        let d = 0;
        console.log("Réponses : ", reponses);
        console.log('Longueur des réponses : ', reponses.length);
        for (const reponse of reponses) {
          console.log('Réponse choisie : ', reponse.choisi);
          switch (reponse.choisi) {
            case 0:
              a++;
              break;
            case 1:
             b++;
             break;
            case 2:
              c++;
              break;
            case 3:
              d++;
              break;
          }
        }
        console.log('----- Résultats -----');
        console.log('A : ', a);
        console.log('B : ', b);
        console.log('C : ', c);
        console.log('D : ', d);

        this.calculerTribu(a,b,c,d);
      })
    }
  }

  calculerTribu = (a, b, c, d) => {
    let resultat;
    // Écureuils bleus
    if (
      (a <= 3 && b <= 3 && c <= 3 && d >= 12) || 
      (a <= 3 && b <= 3 && c <= 7 && d >= 8) || 
      (a <= 3 && b <= 3 && (c >= 8 && c <= 11) && (d >= 12 && d <= 15)) ||
      (a <= 3 && (b >= 4 && b <= 7) && c <= 3 && d >= 8) ||
      (a <= 3 && (b >= 4 && b <= 7) && (c >= 4 && c <= 7) && (d >= 8 && d <= 15)) ||
      (a <= 3 && (b >= 8 && b <= 11) && c <= 3 && (d >= 12 && d <= 15)) ||
      ((a >= 4 && a <= 7) && b <= 3 && c <= 7 && d >= 8) ||
      ((a >= 4 && a <= 7) && (b >= 4 && b <= 7) && c <= 3 && (d >= 8 && d <= 15)) || 
      ((a >= 4 && a <= 7) && (b >= 4 && b <= 7) && (c >= 4 && c <= 7) && (d >= 8 && d <= 11)) ||
      ((a >= 8 && a <= 11) && b <= 3 && c <= 3 && (d >= 12 && d <= 15))
    ) {
      console.log('0');
      resultat = {
        id: 0,
        role: "Évaluateur",
        totem: "Les écureuils bleus"
      }
    } 
    // Abeilles impériales
    else if ( 
      (a <= 3 && b <= 3 && (c >= 8 && c <= 11) && (d >= 4 && d <= 7)) ||
      (a <= 3 && b <=3 && (c >= 12 && c <= 22) && d <= 11) ||
      (a <= 3 && (b >= 4 && b <= 7) && (c >= 8 && c <= 19) && d <= 7) ||
      (a <= 3 && (b >= 8 && b <= 11) && (c >= 12 && c <= 15) && d <= 3) || 
      ((a >= 4 && a <= 7) && b <= 3 && (c >= 8 && c <= 19) && d <= 7) ||
      ((a >= 4 && a <= 7) && (b >= 4 && b <= 7) && (c >= 8 && c <= 15) && d <= 7) ||
      ((a >= 8 && a <= 11) && b <= 3 && (c >= 12 && c <= 15) && d <= 3)
    ) {
      console.log('1');
      resultat = {
        id: 1,
        role: "Supporteur",
        totem: "Les abeilles impériales"
      }
    }
    // Lions rouges
    else if (
      (a >= 12 && b <= 11 && c <= 11 && d <= 11) ||
      ((a >= 8 && a <= 11) && (b >= 4 && b <= 7) && c <= 7 && d <= 7) ||
      ((a >= 8 && a <= 11) && b <= 3 && c <= 7 && d <= 7) 
    ) {
      console.log('2');
      resultat = {
        id: 2,
        role: "Conducteur",
        totem: "Les lions rouges"
      }
    }
    // Fourmis pins
    else if (a <= 7 && b <= 7 && (c >= 8 && c <= 11) && (d >= 8 && d <= 11)) {
      console.log('3');
      resultat = {
        id: 3,
        role: "Coordinateur",
        totem: "Les fourmis pins"
      }
    }
    // Joker
    else if (a <= 7 && b <= 7 && c <= 7 && d <= 7) {
      console.log('4');
      resultat = {
        id: 4,
        role: "Équilibré",
        totem: "Les joker des animaux totems"
      }
    }
    // Loutres sauges
    else if (a <= 7 && (b >= 8 && b <= 11) && c <= 7 && (d >= 8 && d <= 11)) {
      console.log('5');
      resultat = {
        id: 5,
        role: "Promoteur Évaluateur",
        totem: "Les loutres sauges"
      }
    }
    // Castors émeraudes
    else if (a <= 7 && (b >= 8 && b <= 11) && (c >= 8 && c <= 11) && d <= 7) {
      console.log('6');
      resultat = {
        id: 6,
        role: "Facilitateur",
        totem: "Les castors émeraudes"
      }
    }
    // Poulpes violets
    else if ((a >= 8 && a <= 11) && b <= 7 && c <= 7 && (d >= 8 && d <= 11)) {
      console.log('7');
      resultat = {
        id: 7,
        role: "Organisateur",
        totem: "Les poulpes violets"
      }
    }
    // Éléphants orange
    else if ((a >= 8 && a <= 11) && (b >= 8 && b <= 11) && c <= 7 && d <= 7) {
      console.log('8');
      resultat = {
        id: 8,
        role: "Motivateur",
        totem: "Les éléphants orange"
      }
    }
    // Faucons bronzes
    else if ((a >= 8 && a <= 11) && b <= 7 && (c >= 8 && c <= 11) && d <= 7) {
      console.log('9');
      resultat = {
        id: 9,
        role: "Conducteur Supporteur",
        totem: "Les faucons bronzes"
      }
    }
    // Dauphins jaunes 
    else {
      console.log('10');
      resultat = {
        id: 10,
        role: "Promoteur",
        totem: "Les dauphins jaunes"
      }
    }
    const indexTribu = this.state.tribus.findIndex(tribu => tribu.id === resultat.id);
    if (indexTribu !== -1) {
      this.setState({resultat: this.state.tribus[indexTribu]});
    }
  }

  render() {
    return (
      <View style={[styles.container, {borderWidth: isMobile ? 0 : 1}]}>
        {Dimensions.get('screen').height > 700 && 
          <Image source={require('../assets/aneo.svg')} style={{height: 40, width: 100, marginTop: 10}}/>
        }
        {this.state.descriptionLongue ? (
          <View style={styles.descriptionContainer}>
            <Text 
              style={[styles.texteDescription, {color: this.state.resultat?.couleur, 
              maxHeight: Dimensions.get('window').height > 700 ? 520 : 440, 
              fontSize: Dimensions.get('window').height > 700 ? 15 : 13}]}
            >
              {this.state.resultat?.texte_long}
            </Text>
            <Pressable 
              style={[styles.bouton, {width: 100}]} 
              onPress={() => this.setState({descriptionLongue: false})}
            >
              <Text style={styles.texteBouton}>Retour</Text>
            </Pressable>
          </View>
        ) : (
          <Fragment>
            <ImageBackground 
              source={require('../assets/totem.png')} 
              style={{width: '100%', marginTop: 50, paddingBottom: 50}} 
              resizeMode='stretch'
            >
              { this.state.questions.length ? (
                !this.state.done ? (
                  <Question 
                    question={this.state.questions[this.state.questionIndex]} 
                    suivante={this.questionSuivante}
                    index={this.state.questionIndex}
                    longueur={this.state.questions.length}
                  />
                ) : (
                  <Resultat 
                    tribu={this.state.resultat}
                    descriptionLongue={() => this.setState({descriptionLongue: true})}
                  />
                )
              ) : (
                <Text>Chargement...</Text>
              )
              }
            </ImageBackground>
          </Fragment>
        )
        }
      </View>
    )
  }
}

// styles are as before
const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    borderRadius: 10,
    borderColor: '#FFB600',
    maxHeight: 690,
    overflow: 'hidden',
  },
  descriptionContainer: {
    backgroundColor: 'white',
    marginTop: 10,
    padding: 20,
    width: 370
  },
  texteDescription: {
    overflow: 'scroll',
    textAlign: 'justify',
    maxHeight: 450
  },
  bouton: {
    backgroundColor: '#DA5D00',
    alignSelf: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 30,
    height: 35,
    width: 150,
    borderRadius: 10,
  },
  texteBouton : {
    color: 'white',
    fontWeight: 'bold'
  },
})