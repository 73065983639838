import React from 'react'
import { View, Text, StyleSheet, Pressable, Dimensions } from 'react-native'
import { Form, FormItem } from 'react-native-form-component';

export class Formulaire extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      selected: 0,
      erreur: false,
      email: '',
      prenom: '',
      nom: '',
      alert: false,
      done: false
    }
  }

  componentDidMount() {
  }

  envoyerDonnes = async() => {
    if (this.state.prenom !== '' && this.validateIsEmail(this.state.email)) {
      const body = { 
        email: this.state.email,
        prenom: this.state.prenom,
        nom: this.state.nom
      }
      const response = await fetch('https://www.znoue.org/api/player/', {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(body)
      });
      const responseJson = await response.json();
      console.log('Server response : ', responseJson);
      this.setState({done: true})
    } else {
      this.setState({alert: true}, () => {
        setTimeout(() => {
          this.setState({alert: false})
        }, 3000);
      })
    }
  }

  validateIsEmail = (email) => {
    return /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email);
  }

  render() {
      return (
        <View style={{marginTop: -20}}>
          {this.state.done ? (
            <Text style={{marginTop: 120, textAlign: 'center', fontSize: 20}}>Merci et à bientôt !</Text>
          ) : (
            <Form 
              buttonText='Envoyer' 
              buttonStyle={{width: 200, height: 35, backgroundColor: '#DA5D01', alignSelf: 'center'}}
              onButtonPress={() => this.envoyerDonnes()}
            >
              <FormItem
                label="Prénom"
                isRequired
                value={this.state.prenom}
                onChangeText={(prenom) => this.setState({prenom})}
                asterik
                showErrorIcon={false}
              />
              <FormItem
                label="Nom"
                value={this.state.nom}
                onChangeText={(nom) => this.setState({nom})}
              />
              <FormItem
                label="Email"
                isRequired
                value={this.state.email}
                onChangeText={(email) => this.setState({email})}
                asterik
              />
              {this.state.alert && 
                <Text style={{textAlign: 'center'}}>Données invalides, merci de vérifier</Text>
              }
            </Form>
          )}
        </View>
      )
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    width: 370,
    minHeight: 600,
    borderRadius: 10,
    overflow: 'hidden'
  },
})