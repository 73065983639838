import React from 'react'
import { View, Text, StyleSheet, Pressable, Dimensions } from 'react-native'
import { RadioButton } from 'react-native-paper';

export class Question extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      selected: 0,
      erreur: false
    }

    this.reponses = []
  }

  componentDidMount() {
    this.setState({selected: -1})
  }

  sauverReponse() {
    if (this.state.selected !== -1) {
      this.setState({selected: -1});
      this.reponses.push({...this.props.question, choisi: this.state.selected});
      this.props.suivante(this.reponses);
    } else {
      this.setState({erreur: true}, () => {
        setTimeout(() => {
          this.setState({erreur: false})
        }, 2000);
      })
    }
  }

  render() {
      return (
        <View style={[styles.container, {marginTop: Dimensions.get('screen').height > 700 ? -50 : -60}]}>
          <Text style={[styles.question, {height: Dimensions.get('screen').height > 700 ? 220 : 180}]}>
            {this.props.question.texte}
          </Text>
          <Text style={styles.progression}>{this.props.index + 1} / {this.props.longueur}</Text>
          <View style={styles.reponseContainer}>
            {this.props.question.reponses.map(reponse => 
              <View key={reponse.id} style={styles.reponse}>
                <RadioButton
                  uncheckedColor='#b04b00'
                  color="#DA5D00"
                  value={reponse.id}
                  status={ this.state.selected === reponse.id ? 'checked' : 'unchecked' }
                  onPress={() => this.setState({selected: reponse.id})}
                />
                <Pressable style={{flex: 1}} onPress={() => this.setState({selected: reponse.id})}>
                  <Text style={styles.texteReponse}>{reponse.texte}</Text>
                </Pressable>
              </View>
            )}
          </View>
          <Pressable 
            style={styles.bouton} 
            onPress={() => this.sauverReponse()}
          >
            <Text style={styles.texteBouton}>Valider</Text>
          </Pressable>
        </View>
      )
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    width: 370,
    minHeight: 600,
    borderRadius: 10,
    overflow: 'hidden'
  },
  question: {
    marginTop: 10,
    alignSelf: 'center',
    fontWeight: 'bold',
    fontSize: 18,
    textAlign: 'center',
    color: '#DA5D00',
    paddingHorizontal: 15,
  },
  reponseContainer: {
    backgroundColor: 'rgba(255,255,255,0.8)', 
    borderRadius: 10, 
    marginHorizontal: 10, 
    paddingHorizontal: 5, 
    paddingVertical: 8
  },
  reponse: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    height: 60,
  },
  texteReponse: {
    fontSize: 15,
    marginLeft: 5,
    color: '#b04b00',
  },
  bouton: {
    backgroundColor: '#DA5D00',
    alignSelf: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 30,
    height: 35,
    width: 100,
    borderRadius: 10,
    marginBottom: 30
  },
  texteBouton : {
    color: 'white',
    fontWeight: 'bold'
  },
  erreur: {
    marginTop: 20,
    color: 'white'
  },
  progression: {
    fontSize: 18,
    marginTop: -25,
    marginBottom: 5,
    fontWeight: 'bold',
    textAlign: 'center',
    color: 'white'
  }
})